
import React, { Component } from "react";




export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
              <h5 className="text-center">
                {this.props.title1}
              </h5>
              <h4 className="text-center p-2">
               <b>{this.props.title2}</b> 
              </h4>
              {/*  <h4 className="text-center pb-5">
                {this.props.message}
              </h4>*/}
              <h5 className="text-center pb-5" >
                În cazul nostru, <br />
               <b>Ramona și Alex</b> , <br />
                a fost puţin altfel, dar poate tocmai de aceea suntem mai fericiţi. <br/>
                Mai întâi a venit pe lume băiatul nostru <br/> <b>Matei-Ștefan</b> , <br/>
                apoi ne-am decis să ne unim destinele şi în faţa lui Dumnezeu. <br/>
                Aşadar, <b> Sâmbătă, 26 Noiembrie 2022, </b><br/>
                vă invităm să ne însoțiți la ceremonia religioasă, 
                ce va avea loc la Biserica <b> Sf. M. Mc. Gheorghe Slobozia</b> începând cu <b>orele 16:00</b>. <br/>
                Un rol foarte important îl vor avea naşii noștrii <br/>
               <b>Ana și Andrei Grecu</b>, <br/>
                alături de părinții noștrii <br/>
               <b>Elena  & Ionuț Cazacu </b> <br/>
               <b>Amelia & Vlad Rotaru.</b>  <br/>
                Petrecerea va avea loc la  Restaurantul <b>Taboo Events Slobozia</b>,
                începând cu <b>orele 18:00</b>, <br/> 
                seară în care vom sărbători și creștinarea micuțului nostru <br/> <b>Matei-Ștefan</b>. <br/>
              <b>Vă așteptam cu drag!</b>  
              </h5>

              
            </div>
                        </div>
        </div>
                 </div>
              
    );
                }
}

